<template>
  <div style="width:100%;">
    <chat-window
      :lease_change="is_loading"
      chatWindowHeight="medium"
      :dense="true"
      :no-header="true"
      :InteractionObj="contact"
      from="national_account"
      context="'national_account'"
      :currentPropertyId="propertyView"
      ref="TenantChatWindowRef"
    />
  </div>
</template>
<script>
import ChatWindow from "../../communication_center/ChatWindow.vue";
export default {
  name: "Communication",
  data() {
    return {
      is_loading: false,
    };
  },
  props: [
    "showAside",
    "contact",
    "contactLeases",
    "propertyView",
    "pending",
    "payment_methods",
    "access",
  ],
  components: {
    ChatWindow,
  },
};
</script>
